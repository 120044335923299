import React, { useState } from 'react'

import Header from './Header'
import axios from 'axios'

function Contact() {
const [data, setdata] = useState({

    name:"",
    email:'',
    phone:'',
    subject:'',
    message:''
});
const [resp, setresp] = useState(null);

const handlecontact=(e)=>{
    e.preventDefault()
   
    axios.post('https://tazima.fortinotech.com.et/contact', {name:data.name,phone:data.phone,email:data.email,subject:data.subject,message:data.message})
      .then(res => {
        if (res.data.status==="success") {
        setresp(res.data.status)
        setdata({name:"",phone:"",email:"",subject:"",message:""})
        }
      })
      .catch(err => console.log(err))


}

  return (
    <div>
  <Header/>


    <div className="container-fluid contact bg-light py-5 mt-0">
            <div className="container py-5">
                
                <div className=" g-5 align-items-center">
                <div className="contact wow fadeInUp">
                <div className="container">
                    <div className="section-header text-center">
                        <h4>Get In Touch</h4>
                        <h2>For Any Query</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-6 px-0">
                            <div className="contact-info">
                                <div className="contact-item">
                                    <i className="flaticon-address"></i>
                                    <div className="contact-text">
                                        <h2>Location</h2>
                                        <p>Ayat , Addis Ababa, Ethiopia</p>
                                    </div>
                                </div>
                                <div className="contact-item">
                                    <i className="flaticon-call"></i>
                                    <div className="contact-text ">
                                        <h2>Phone</h2>
                                        <p>+251 911239615</p>
                                    </div>
                                </div>
                                <div className="contact-item px-2 px-md-4">
                                    <i className="flaticon-send-mail"></i>
                                    <div className="contact-text">
                                        <h2>Email</h2>
                                        <p>tazimacons2021@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6"  style={{backgroundColor:' #EC5B3A'}}>
                            <div className="contact-form">
                                <div id="success"></div>
                                <form onSubmit={handlecontact}>
                              { resp && <div className="alert alert-success" role="alert">
                                    <strong>Your message is delivered!  Thanks for contacting us  </strong>
                                </div>}
                                        <div className="form-group mb-2">
                                                 <input type="text" value={data.name} onChange={e => setdata({ ...data, name: e.target.value })}
                                            className="form-control border-3  "  required placeholder="Enter your name"/>
                                        </div>
                                        <div className="form-group mb-2">
                                                 <input type="number" maxLength={10} value={data.phone} onChange={e => setdata({ ...data, phone: e.target.value })}
                                            className="form-control border-3  "  required  placeholder="Enter your Phone number"/>
                                        </div>
                                    <div className="control-group  mb-2">
                                    <input type="email" value={data.email} onChange={e => setdata({ ...data, email: e.target.value })}
                                    className="form-control border-3  "  required  placeholder="Enter your Email"/>
                                    </div>
                                    <div className="control-group  mb-2">
                                    <input type="text" value={data.subject} onChange={e => setdata({ ...data, subject: e.target.value })}
                                    className="form-control border-3  "  required  placeholder="Enter Subject"/>
                                    </div>
                                    <div className="control-group  mb-2">
                                        <textarea className="form-control border-3" value={data.message} onChange={e => setdata({ ...data, message: e.target.value })}  placeholder="Message" required rows={2} ></textarea>
                                       
                                    </div>
                                    
                                    <div className="control-group mb-3">
                                    </div>
                                    <div>
                                        <button className="btn" type="submit" id="sendMessageButton">Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                    
                    <div className=" ">
                        <div className="rounded ">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15761.971456358171!2d38.85482657837802!3d9.01872343288923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b9be21aa9423d%3A0x52a264574d8a05bd!2zVGVzZnUgTWFsbCB8IEF5YXQgfCDhibDhiLXhjYkg4Yie4YiNIHwg4Yqg4Yur4Ym1!5e0!3m2!1sen!2set!4v1712964224090!5m2!1sen!2set" width="100%" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    
    
    </div>
  )
}

export default Contact
