import React from 'react'
import Header from './Header'
import CB1 from '../images/CB1.jpeg'

import CV1 from '../images/CV1.jpg'
import CV2 from '../images/CV2.jpg'
import CV3 from '../images/CV3.jpeg'
import CV4 from '../images/CV4.jpeg'
import CV5 from '../images/CV5.jpg'
import CV6 from '../images/CV6.jpeg'
import CV7 from '../images/CV7.jpeg'
import CV8 from '../images/CV8.jpeg'
import CV9 from '../images/CV9.jpeg'
import adetail from '../images/adetail.jpg'
import ayatcondominem from '../images/ayatcondominem.jpg'
import bolearabsa from '../images/bolearabsa.jpg'
import coblestone from '../images/coblestone.jpg'
import tesfumall from '../images/tesfumall.jpg'
import { Link } from 'react-router-dom'
function Conhome() {
  return (
    <div className="wrapper">
    <Header/>
    <div id="carousel" className="carousel slide" data-ride="carousel">
    <ol className="carousel-indicators">
        <li data-target="#carousel" data-slide-to="0" className="active"></li>
        <li data-target="#carousel" data-slide-to="1"></li>
        <li data-target="#carousel" data-slide-to="2"></li>
    </ol>
    <div className="carousel-inner">
        <div className="carousel-item active">
        <img src={CB1} alt=''/>
            <div className="carousel-caption">
                <p className="animated fadeInRight">We Are Professional</p>
                <h1 className="animated fadeInLeft">For Your Dream Project</h1>
               
            </div>
        </div>

      

        
    </div>

   
</div>
    
<div className="about wow fadeInUp" data-wow-delay="0.1s">
<div className="container">
    <div className="row align-items-center">
    <div className="col-lg-5 col-md-6">
    <div className="about-img">
    <img src={adetail} alt=''/>
    </div>
    
</div>
        <div className="col-lg-7">
        <h1 className="display-5 text-uppercase mb-4">Welcome to Tazima Construction Plc.</h1>
       
        <p>At Tazima Construction Plc, we are more than just builders; we are visionaries shaping the landscape of Ethiopia. Established with a commitment to excellence in 2012, Tazima has rapidly emerged as a leading player in the Ethiopian construction industry. Our unwavering dedication to cutting-edge technology, safety, and client satisfaction has propelled us to the forefront of the market.</p>
      
    </div>
  
<h3>Our Vision and Mission</h3>
        <p className="mb-4">Our vision is to be a pioneer of change in the Ethiopian construction industry. We strive for technical excellence, investing in research and development to turn innovative ideas into reality. At Tazima, we offer a reliable and complete construction solution to the Ethiopian market. Our hardworking team, guided by our core values, ensures that every project is executed with precision and integrity. Safety, collaboration, and exceeding promises are at the heart of everything we do. Join us on this transformative journey as we build a brighter future for Ethiopia.</p>
        
    </div>
</div>
</div>









<div className="fact">
<div className="container-fluid">
    <div className="row counters">
        <div className="col-md-6 fact-left wow slideInLeft">
            <div className="row">
                <div className="col-6">
                    <div className="fact-icon">
                        <i className="flaticon-worker"></i>
                    </div>
                    <div className="fact-text">
                        <h2 data-toggle="counter-up">Over 50</h2>
                        <p>Expert Workers </p>
                    </div>
                </div>
                <div className="col-6">
                    <div className="fact-icon">
                        <i className="flaticon-building"></i>
                    </div>
                    <div className="fact-text">
                        <h2 data-toggle="counter-up">Over 231</h2>
                        <p>Happy Clients</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-6 fact-right wow slideInRight">
            <div className="row">
                <div className="col-6">
                    <div className="fact-icon">
                        <i className="flaticon-address"></i>
                    </div>
                    <div className="fact-text">
                        <h2 data-toggle="counter-up">107</h2>
                        <p>Completed Projects</p>
                    </div>
                </div>
                <div className="col-6">
                    <div className="fact-icon">
                        <i className="flaticon-crane"></i>
                    </div>
                    <div className="fact-text">
                        <h2 data-toggle="counter-up">54</h2>
                        <p>Running Projects</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>





<div className="container-fluid bg-light py-6 px-md-5 px-4">
        <div className="text-center mx-auto mb-5" style={{maxWidth: '600px'}}>
            <h1 className="display-5 text-uppercase mb-4">We Provide <span className="text-primary">The Best</span> Construction Services</h1>
        </div>
        <div className="row g-5">
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-white d-flex flex-column align-items-center text-center">
                <img className="img-fluid" src={CV1} alt=""/>
                    <div className="service-icon bg-white">
                        <i className="fa fa-3x fa-building text-primary"></i>
                    </div>
                    <div className="px-4 pb-4">
                        <h4 className="text-uppercase mb-3">Building Construction</h4>
                        <p>Building construction involves the creation of structures using various materials and techniques. Different types of buildings, such as high-rises, commercial spaces, and residential homes, fall under this category. Materials like concrete and protected steel are used for fire-resistant structures, while lightweight materials are common for less fire-resistant buildings.</p>
                       
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
                <img className="img-fluid" src={CV2} alt=""/>
                    <div className="service-icon bg-white">
                        <i className="fa fa-3x fa-home text-primary"></i>
                    </div>
                    <div className="px-4 pb-4">
                        <h4 className="text-uppercase mb-3">House Renovation</h4>
                        <p>When it comes to house renovation, we breathe new life into existing homes. Our team tackles everything from minor upgrades to complete transformations. Whether you need a kitchen remodel, bathroom upgrade, or whole-house renovation, we ensure quality craftsmanship and attention to detail.</p>
                       
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
                <img className="img-fluid" src={CV3} alt=''/>
                    <div className="service-icon bg-white">
                        <i className="fa fa-3x fa-drafting-compass text-primary"></i>
                    </div>
                    <div className="px-4 pb-4">
                        <h4 className="text-uppercase mb-3">Architecture Design</h4>
                        <p>Architectural design is at the heart of what we do. Our architects blend creativity with practicality to design structures that stand out. Whether it’s a modern office building, a sustainable residential complex, or a cultural center, we create architectural marvels that inspire and serve their purpose.</p>
                       
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
                <img className="img-fluid" src={CV4} alt=''/>
                    <div className="service-icon bg-white">
                        <i className="fa fa-3x fa-palette text-primary"></i>
                    </div>
                    <div className="px-4 pb-4">
                        <h4 className="text-uppercase mb-3">Road Construction</h4>
                        <p> Road construction involves the design, building, and maintenance of roads, highways, motorways, and other transportation infrastructure. Our aim is to create durable, safe, and efficient routes for vehicular and pedestrian traffic.</p>
                       
                    </div>
                </div>
            </div>
           
            <div className="col-lg-4 col-md-6">
                <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
                <img className="img-fluid" src={CV5} alt=''/>
                    <div className="service-icon bg-white">
                        <i className="fa fa-3x fa-paint-brush text-primary"></i>
                    </div>
                    <div className="px-4 pb-4">
                        <h4 className="text-uppercase mb-3">Genral finishing work</h4>
                        <p> Our Genral finishing work services add the finishing touch to any project. Whether it’s a residential interior, a commercial facade, or an outdoor mural, we use high-quality paints and skilled techniques. Our goal is to enhance aesthetics, protect surfaces, and leave a lasting impression.</p>
                       
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6">
            <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
            <img className="img-fluid" src={CV6} alt=''/>
                <div className="service-icon bg-white">
                    <i className="fa fa-3x fa-tools text-primary"></i>
                </div>
                <div className="px-4 pb-4">
                    <h4 className="text-uppercase mb-3">Mining and quarrying</h4>
                    <p>Our mining and quarrying operations involve the extraction, processing, and utilization of valuable natural resources from the Earth’s crust.</p>
                   
                </div>
            </div>
        </div>

        <div className="col-lg-4 col-md-6">
        <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
        <img className="img-fluid" src={CV7} alt=''/>
            
            <div className="service-icon h1 bg-white">
                    <i className="bi bi-bricks text-primary object-fit-cover    "></i>
                </div>
            <div className="px-4 pb-4">
                <h4 className="text-uppercase mb-3">HCB manufacturing (ብሎኬት)</h4>
                <p>At our company, we specialize in creating structures using solid concrete bricks as the primary building material. These bricks are made from a mixture of cement, sand, and aggregate, resulting in high strength and durability.</p>
               
            </div>
        </div>
    </div>
    <div className="col-lg-4 col-md-6">
            <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
            <img className="img-fluid" src={CV8} alt=''/>
                <div className="service-icon h1 bg-white">
                <i className="bi bi-grid-3x3 text-primary"></i>
            </div>
                <div className="px-4 pb-4">
                    <h4 className="text-uppercase mb-3">aluminium works</h4>
                    <p>Our aluminium works involve fabricating windows, doors, curtain walls, and structural components using high-quality aluminum. Our skilled craftsmen ensure precision and functionality in every piece.</p>
                   
                </div>
            </div>
        </div>

        <div className="col-lg-4 col-md-6">
        <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
        <img className="img-fluid" src={CV9} alt=''/>
            <div className="service-icon h1 bg-white">
            <i className="bi bi-exclude text-primary"></i>
        </div>
            <div className="px-4 pb-4">
                <h4 className="text-uppercase mb-3">Cobel stone work</h4>
                <p>Cobblestone work is our specialty. We use natural stones like granite or basalt to create durable pavements, walkways, and roads. Our hand-set cobblestones add a rustic and timeless charm to any space.</p>
               
            </div>
        </div>
    </div>

        </div>
    </div>


    <div className="container-fluid bg-light  py-6 px-md-5 px-4" style={{marginBottom: '100px'}}>
    <div className="text-center mx-auto mb-5" style={{maxWidth: '600px'}}>
        <h1 className="display-5 text-uppercase mb-4">Some Of Our <span className="text-primary">Popular</span> Dream Projects</h1>
    </div>
    
    <div className="row g-5 portfolio-container ">
        <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first mb-5">
            <div className="position-relative portfolio-box">
            <img  className="img-fluid w-100" src={ayatcondominem} alt=''/>
                
                <Link className="portfolio-title shadow-sm text-decoration-none " >
                    <p className="h4 text-uppercase  text-center">Ayat Cadmium </p>
                    <span className="text-body text-center"><i className="fa fa-map-marker-alt text-primary me-2"></i> Ayat Addis Abeba</span>
                </Link>
                <a className="portfolio-btn" href="../constraction/img/portfolio-1.jpg" data-lightbox="portfolio">
                    <i className="bi bi-plus text-white"></i>
                </a>
            </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first mb-5">
            <div className="position-relative portfolio-box">
            <img  className="img-fluid w-100" src={coblestone} alt=''/>
                
                <Link className="portfolio-title shadow-sm text-decoration-none " >
                    <p className="h4 text-uppercase  text-center">Coble stone </p>
                    <span className="text-body text-center"><i className="fa fa-map-marker-alt text-primary me-2"></i>Ayat Addis Abeba</span>
                </Link>
                <a className="portfolio-btn" href="../constraction/img/portfolio-1.jpg" data-lightbox="portfolio">
                    <i className="bi bi-plus text-white"></i>
                </a>
            </div>
        </div>
     
    <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first mb-5">
    <div className="position-relative portfolio-box">
    <img  className="img-fluid w-100" src={tesfumall} alt=''/>
        
        <Link className="portfolio-title shadow-sm text-decoration-none " >
            <p className="h4 text-uppercase  text-center">Tesfu Mall</p>
            <span className="text-body text-center"><i className="fa fa-map-marker-alt text-primary me-2"></i>Ayat Addis Abeba</span>
        </Link>
        <a className="portfolio-btn" href="../constraction/img/portfolio-1.jpg" data-lightbox="portfolio">
            <i className="bi bi-plus text-white"></i>
        </a>
    </div>
</div>
<div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first mb-5">
            <div className="position-relative portfolio-box">
            <img  className="img-fluid w-100" src={bolearabsa} alt=''/>
                
                <Link className="portfolio-title shadow-sm text-decoration-none " >
                    <h4 className="h6 text-uppercase text-center ">Bole Arabsa Building</h4>
                    <span className="text-body text-center "><i className="fa fa-map-marker-alt text-primary me-2 "></i>Bole Arabsa Addis Abeba</span>
                </Link>
                <a className="portfolio-btn" href="../constraction/img/portfolio-1.jpg" data-lightbox="portfolio">
                    <i className="bi bi-plus text-white"></i>
                </a>
            </div>
        </div>
    </div>
</div>






    </div>
  )
}

export default Conhome