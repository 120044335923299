import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AdminHeader from './AdminHeader'
import axios from 'axios';

function AdminBlog() {

    const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("https://tazima.fortinotech.com.et/blogfetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);
    const deleteblog=(id)=>{

        const conf=window.confirm(` Are you sure  you Want to Delete this blog? `)
        if(conf){
            axios.delete("https://tazima.fortinotech.com.et/deleteblog/"+id)
            .then(res=>{
              if (res.data.status==="success") {
                alert("you have seccessfully deleted the Blog!")
               
                
              }
            })
            .catch(err=>console.log(err))
        }
    
    }
   

  return (
    <div>
    <AdminHeader/>
    <div className="blog">

        <div className="container">
            <div className="row session-title">
                <h2>You can Delete and UpdateEach Blogs one by one</h2>
               
            </div>
            <div className="row blog-row">
            {data.map((blog,i)=>{
                return   <div className="col-md-6 col-sm-12" key={i}>
                   <div className="blog-singe no-margin ">
                       <div className="blog-img-tab">
                          { <img src={'https://tazima.fortinotech.com.et/images/' + blog.image} alt=""/>}
                       </div>
                       <div className=" blog-content-tab">
                           <h2>{blog.subject}</h2>
                           <p>{blog.date}</p>
                           <p className="blog-desic">{blog.detail} </p>
                           <div className="d-flex  justify-content-center">
                           <Link onClick={()=>deleteblog(blog.id)} className="btn rounded btn-dark bg-danger text-white  mr-4   " href="">Delete Blog</Link>
                           <Link  to={`/updateblog/${blog.id}`} className="btn rounded btn-dark bg-info text-white  " href="">Update Blog</Link>
                           </div>
                       </div>
                   </div>
               </div>
                  }) }

        


            </div>
        </div>
        
    </div>
    
    </div>
  )
}

export default AdminBlog