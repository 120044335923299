import React, { useState } from 'react'
import about from '../../images/about.jpg'
import { signInWithEmailAndPassword} from "firebase/auth"

import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../firebase_con'
function Login() {
  const [data, setdata] = useState({

    email:'',
    password:''
  });
  const [err, seterr] = useState(null);
 

  const navigate=useNavigate()
  
  const login=(e)=>{
    e.preventDefault()
  
   signInWithEmailAndPassword(auth,data.email,data.password)
  .then(message=>{
    if(message.email!==null)
    navigate('/dashboard')
  })
    .catch(err=>seterr(err.message))
 
  }
  return (
    <div>
    <div className="nav-bar" style={{backgroundColor:' #0A1025'}}>
    <div className="container-fluid">
        <nav className="navbar navbar-expand-lg bg-dark navbar-dark">

            <Link to={'/'} className=""><img style={{width:'50px'}} className="rounded-circle " src='../assets/img/Tazima Logo.jpg' alt=''/></Link>
    
   
           
               
           
        </nav>
    </div>
    </div>
    <Link to={'/'} class="btn btn-success">Back to user page</Link>



  <div class="container py-5 my-5 ">
 
    <div class="row g-0 align-items-center mb-5">
    <div class="col-lg-6 mb-5 mb-lg-0 d-flex justify-content-center ">

      <img src={about} className="w-75 rounded-4 shadow-4" alt=''/>
      
      </div>
      
      <div class="col-lg-6 mb-5 mb-lg-0">
        <div class="card cascading-right" style={{    background: 'hsla(0, 0%, 100%, 0.55)',
          backdropFilter: 'blur(30px)'}}
        
            >
          <div class="card-body p-lg-5  shadow-5 text-center mb-5">
            <h2 class="fw-bold ">Sign in now</h2>
            <form onSubmit={login} className="pt-3">
            {err &&<div class="alert alert-primary" role="alert">
             <strong>{err}</strong>
            </div>}
               <div className="form-group mb-3">
                 <input type="email" className="form-control "  onChange={e => setdata({ ...data, email: e.target.value })}
                   placeholder="Enter Email" required/>
               </div>
               <div className="form-group">
                 <input type="password" className="form-control "  onChange={e => setdata({ ...data, password: e.target.value })}
                   placeholder="Enter Password" required/>
               </div>
               <div className="mt-3">
                 <button type='submit' className="btn btn-block w-100 btn-primary btn-lg font-weight-medium auth-form-btn"
                  >SIGN IN</button>
               </div>

             </form>
          </div>
        </div>
      </div>

      
    </div>
  </div>
    
    </div>
  )
}

export default Login