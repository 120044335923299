


import './App.css';
import { Routes,Route,BrowserRouter} from 'react-router-dom';


import Home from './Pages/Home';
import Footer from './Pages/Footer';

import Contact from './Pages/Contact';
import Conhome from './Pages/Conhome';
import Health from './Pages/Health';
import Delvery from './Pages/Delivery';
import About from './Pages/About';
import Blog from './Pages/Blog';
import Dashbord from './Pages/Admin/Dashbord';
import AdminBlog from './Pages/Admin/AdminBlog';
import CreateBlog from './Pages/Admin/CreateBlog';
import Viewmessage from './Pages/Admin/Viewmessage';
import Login from './Pages/Admin/Login';
import BlogDetail from './Pages/BlogDetail';
import BlogDetailList from './Pages/BlogDetaiList';
import UpdateBlog from './Pages/Admin/UpdateBlog';
import { useEffect, useState } from 'react';
import { auth } from './firebase_con';
import NotFoundPage from './Pages/NotFoundPage';
import Teammanagement from './Pages/Admin/Team_management';

function App() {
const [user, setuser] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged((authUser)=>{
      if(authUser){
       
      setuser(authUser)

      }})
    }, []);
   console.log(user);
 
  return (
    <BrowserRouter>  

    <Routes>
   
    <Route path='/' element={<div><Home/><Footer/></div>} />
    <Route path='/contact' element={<div><Contact/><Footer/></div>} />
    <Route path='/constraction' element={<div><Conhome/><Footer/></div>} />
    <Route path='/medical' element={<div><Health/><Footer/></div>} />
    <Route path='/delivery' element={<div><Delvery/><Footer/></div>}/>
    <Route path='/blogdetail/:id' element={<div><BlogDetail/><Footer/></div>}/>
    <Route path='/blogdetaillist/:id' element={<div><BlogDetailList/><Footer/></div>}/>
    <Route path='/about' element={<div><About/><Footer/></div>}/>
    <Route path='/blog' element={<div><Blog/><Footer/></div>}/>
   {user &&<Route path='/dashboard' element={<div><Dashbord/></div>}/>}
    {user &&<Route path='/adminblog' element={<div><AdminBlog/></div>}/>}
    {user &&<Route path='/createblog' element={<div><CreateBlog/></div>}/>}
    {user &&<Route path='/updateblog/:id' element={<div><UpdateBlog/></div>}/>}
    {user &&<Route path='/viewmessage' element={<div><Viewmessage/></div>}/>}
    {user &&<Route path='/teamcontrol' element={<div><Teammanagement/></div>}/>}
    <Route path='/tazimaloginpage2024' element={<div><Login/></div>}/>
    <Route path="*"  element={<NotFoundPage />} />

   
  </Routes>

  </BrowserRouter>

  );
}

export default App;
