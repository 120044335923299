import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function HomBlog() {

    const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("https://tazima.fortinotech.com.et/blogfetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id).slice(0, 4))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);
    const truncate = (str, len) => str.slice(0, len);
  return (
    <div className='mb-3'>
    
    <div className="blog">

        <div className="container">
            <div className="row session-title">
               {data.length>0 && <h2>Latest Blogs</h2>}
               
            </div>
            <div className="row blog-row  ">
            {data.map((blog,i)=>{
                return   <div className="col-md-6 col-sm-12 gap-1   " key={i}>
                   <div className="blog-singe no-margin ">
                       <div className="blog-img-tab p-md-2 object-fit-cover    ">
                          { <img className="rounded-2" src={'https://tazima.fortinotech.com.et/images/' + blog.image} alt=""/>}
                       </div>
                       <div className="blog-content-tab">
                           <h2><strong>{blog.subject}</strong></h2>
                           <p>{blog.date}</p>
                           <p className="blog-desic mb-0">{truncate(blog.detail, 230)}.... </p>
                           <div className='d-flex justify-content-end '>
                           <Link className='mt-0 ' to={`/blogdetail/${blog.id}`}>Read More <i className="fas fa-arrow-right"></i></Link>
                           </div>
                       </div>
                   </div>
               </div>
                  }) }
              


            </div>
        </div>
      {data.length>0 &&  <div className="d-flex justify-content-end ">
        <Link to={'/blog'} className="btn rounded btn-dark bg-dark text-white   " href=""> More Blogs</Link>
        </div>}
    </div>
    
    </div>
  )
}

export default HomBlog