import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function TeamDelete() {
    const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("https://tazima.fortinotech.com.et/teamfetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);


    const deleteTea=(id)=>{

     

        const conf=window.confirm(` Are you sure  you Want to Delete this Team member? `)
        if(conf){
            axios.delete("https://tazima.fortinotech.com.et/deleteteam/"+id)
            .then(res=>{
              if (res.data.status==="success") {
                alert("you have seccessfully deleted the Team!")
              
                
              }
            })
            .catch(err=>console.log(err))
        }
    
    }


  return (
    <div>


    <div className="team">
    <div className="container">
       
        <div className="row">
   
               {data.map((teams,i)=>{
            return             <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s" key={i}>
             <div className="team-item" >
                <div className="team-img">
                <img src={'https://tazima.fortinotech.com.et/images/' + teams.Image} alt=""/>
                </div>
                <div className="team-text">
                    <h2>{teams.Name}</h2>
                    <p>{teams.Position}</p>
                </div>
                <div className="team-social">
                    <Link onClick={()=>deleteTea(teams.id)} className="social-tw" ><i class="bi bi-trash3"></i></Link>
                 
                </div>
            </div>
            </div>
               }) }
            
     
        </div>
    </div>
</div>
    
    </div>
  )
}

export default TeamDelete