import axios from 'axios';
import React, { useEffect, useState } from 'react'

function Team() {

    const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("https://tazima.fortinotech.com.et/teamfetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);


  return (
    <div>
   {data.length>0 && <div className="team">
                <div className="container">
                    <div className="section-header text-center pb-4">
                        
                        <h2>Meet Our Team</h2>
                    </div>
                    <div className="row d-flex justify-content-center">
                
                        
                           {data.map((members,i)=>{
                    return         <div className="col-lg-3 col-md-6 wow fadeInUp rounded-3  " data-wow-delay="0.1s" key={i}>
                    <div className="team-item" >
                    <div className="team-img">
                    <img className='rounded ccc' src={'https://tazima.fortinotech.com.et/images/' + members.Image} alt=""/>
                    </div>
                    <div className="team-text rounded-bottom   ">
                        <h2 className='d-flex justify-content-center'>{members.Name}</h2>
                        <p className='d-flex justify-content-center'>{members.Position}</p>
                    </div>
                          
                        </div>
                        </div>
                           }) }
                       
                    </div>
                </div>
            </div>}
    
    
    </div>
  )
}

export default Team