import React from 'react'
import Header from './Header'
import { Link } from 'react-router-dom'
import Team from './Team'
import HomBlog from './HomBlog'
import HB1 from '../images/homebanner1.jpeg'
import HB2 from '../images/homebanner2.jpeg'

import S1 from '../images/S1.gif'
import S2 from '../images/S2.gif'
import S3 from '../images/S3.jpeg'
import about from '../images/about.jpg'
import db from '../images/db.jpeg'
function Home() {
  return (
    <div className="wrapper">
    <Header/>
    <div id="carousel" className="carousel slide" data-ride="carousel">
    <ol className="carousel-indicators">
        <li data-target="#carousel" data-slide-to="0" className="active"></li>
        <li data-target="#carousel" data-slide-to="1"></li>
        <li data-target="#carousel" data-slide-to="2"></li>
    </ol>
    <div className="carousel-inner">
        <div className="carousel-item active">
<img src={HB1} alt=''/>
            <div className="carousel-caption">
                <p className="animated fadeInRight">We Are Professional</p>
                <h1 className="animated fadeInLeft">For Your Dream Project</h1>
                <Link className="btn animated fadeInUp" to={'/constraction'}>Discover more</Link>
            </div>
        </div>

        <div className="carousel-item">
        <img src={HB2} alt=''/>
            <div className="carousel-caption">
                <p className="animated fadeInRight">Professional Medicine & medical device import
                </p>
                <h1 className="animated fadeInLeft">Trusted and Quality services </h1>
                <Link className="btn animated fadeInUp" to={'/medical'}>Discover more</Link>
            </div>
        </div>

        <div className="carousel-item">
        <img src={db} alt=''/>

            <div className="carousel-caption">
                <p className="animated fadeInRight"> Trusted Delivery service</p>
               
                <Link className="btn animated fadeInUp" to={'/delivery'}>Discover more</Link>
            </div>
        </div>
    </div>

    <a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
    </a>
    <a className="carousel-control-next" href="#carousel" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
    </a>
</div>
    







<div className="about wow fadeInUp" data-wow-delay="0.1s">
<div className="container">
    <div className="row align-items-center">
        <div className="col-lg-5 col-md-6">
            <div className="about-img">
            <img src={about} alt=''/>
            </div>
        </div>
        <div className="col-lg-7 col-md-6">
            <div className="section-header text-left">
                <h1>Welcome to Tazima</h1>

            </div>
            <div className="about-text">
                <p>
                Tazima is an Ethiopian  company established in 2012 E.C by a 
                young visionary and licensed general contractor 
                called Sertsemichael Guchima (ሠርፀሚካኤል ጉችማ)
                </p>
                <p>
                Sertse established Tazima as a General Contractor with the aim of participating in different architectural and engineering projects to be implemented throughout the country. 
                </p>
                <p>
                Since its establishment, Tazma has undertaken and executed various 
                construction projects of diverse nature ranging from small scale residential buildings to large scale governmental & private complexes. 
            </p>
           
                <div className="d-flex justify-content-center justify-content-md-end ">
                <Link to={'/about'} className="btn rounded btn-dark bg-dark text-white   " href=""> Discover More</Link>
                </div>
            </div>
        </div>
    </div>
</div>
</div>









<div className="service">
<div className="container">
    <div className="section-header text-center">
        <h5>Our Services</h5>
        <h2>We Provide Services</h2>
    </div>
    <div className="row">
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="service-item">
                <div className="service-img">
                <img src={S1} alt=''/>
                    <div className="service-overlay">
                        <p>Our construction services encompass a wide range of projects, from residential homes to commercial complexes. We specialize in constructing robust, safe, and aesthetically pleasing structures....
                           
                        </p>
                    </div>
                </div>
                <div className="service-text">
                    <h3>Tazima Construction</h3>
                    <Link to={'/constraction'} className="btn"  data-lightbox="service"><i className="fas fa-arrow-right"></i></Link>
                </div>
            </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
            <div className="service-item">
                <div className="service-img">
                <img src={S2} alt=''/>
                    <div className="service-overlay">
                        <p>
                        Our delivery service is a well-structured system that ensures timely and efficient provision of products or services to our valued customers. 
                        </p>
                    </div>
                </div>
                <div className="service-text">
                    <h3>Tazima Delivery</h3>
                    <Link className="btn" to={'/delivery'} data-lightbox="service"><i className="fas fa-arrow-right"></i></Link>
                </div>
            </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item">
                <div className="service-img">
                <img src={S3} alt=''/>
                    <div className="service-overlay">
                        <p>
                       Our expertise lies in sourcing and delivering a wide range of medicines and medical devices to healthcare institutions across the region. We ensure that all products meet the highest standards of quality and safety, complying with international regulations.
                        </p>
                    </div>
                </div>
                <div className="service-text">
                    <h3>Medicine & medical device import </h3>
                    <Link className="btn" to={'/medical'} data-lightbox="service"><i className="fas fa-arrow-right"></i></Link>
                </div>
            </div>
        </div>
        
    </div>
</div>
</div>
<HomBlog/>
<Team/> 

<div className='d-flex justify-content-end '>
<Link to={'/tazimaloginpage2024'} className='btn btn-white text-white' >admin</Link>
</div>
    </div>
  )
}

export default Home