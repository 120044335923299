import React from 'react'
import { useNavigate } from 'react-router-dom'
import animationnot from '../images/404_animation.gif'
function NotFoundPage() {
   
    const navigate=useNavigate()
    const handleGoPage=()=>{
  
   navigate('/')
 
    }
  return (
    <div className='d-flex align-items-center justify-content-center vh-100'>
    <div>
    <img className='image404' src={animationnot} alt='notFound'/>
   <div className='mx-5'>
   <h1>Dear Customer this page is not found</h1>

    <div>
    <button type="button" onClick={handleGoPage} className="btn w-100 btn-dark">Back</button>
    </div>
    </div>
    </div>
    </div>
  )
}

export default NotFoundPage