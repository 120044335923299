import React, { useEffect, useState } from 'react'
import Header from './Header'

import adetail from '../images/adetail.jpg'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios';
function BlogDetailList() {
	const { id } = useParams();
	const [data, setdata] = useState([]);
	useEffect(() => {
	  axios.get('https://tazima.fortinotech.com.et/detail/' + id)
		.then(res => {
		  if (res.data.status === "success") {
			setdata(res.data.Result)
		  }
		})
		.catch(err => console.log(err))
	}, [id]);
	
  

  return (
    <div>
    <Header/>
    <div className="page-nav page-headerr no-margin row ">
<div className="container">
    <div className="row">
        <h2 className="text-white ">ጣዝማ</h2>
        <ul className="d-flex justify-content-center  ">
            <li > <Link to={'/'} className="text-white "><i className="fas fa-home text-white"></i> Home</Link></li>
            <li className="text-white "><i className="fas fa-angle-double-right text-white"></i> Our Blog</li>
        </ul>
    </div>
</div>
</div>
    <section class="section blog-wrap bg-gray mt-0">
    <div class="container">
        <div class="row">
        <div class="col-lg-4">
                <div class="sidebar-wrap">
	

	<div class="sidebar-widget card border-0 mb-3">
        <img src={adetail} alt='' class="img-fluid "/>
		<div class="card-body p-4 text-center">
			<h5 class="mb-0 mt-4">ጣዝማ</h5>
			<h6>Keep in Touch with our Updates</h6>
			<p>we are available on facebook and other social medias you can be our member and follow up our activities</p>

			<ul class="list-inline author-socials">
				<li class="list-inline-item mr-3">
					<Link to={'https://www.facebook.com/tazimacons2013'}><i class="fab fa-facebook-f text-muted"></i></Link>
				</li>
				<li class="list-inline-item mr-3">
					<Link ><i class="fab fa-twitter text-muted"></i></Link>
				</li>
				
			</ul>
		</div>
	</div>

	

	
</div>
            </div> 
            <div class="col-lg-8">
                <div class="row">
	<div class="  mb-5">
		{data.map((detail,i)=>{
		return	<div class="blog-item mx-md-0 mx-2" key={i}>
			
            
			{ <img src={'https://tazima.fortinotech.com.et/images/' + detail.image} class="img-fluid rounded   " alt=""/>}
			<div class="blog-item-content bg-white p-4">
				
<p className='d-flex justify-content-end '>
{detail.date}</p>
				<h3 class="mt-3 mb-3">{detail.subject}</h3>
				<p class="mb-4">{detail.detail}</p>

				
			</div>
		</div>

		})}
	</div>

	

	
</div>
            </div>
              
        </div>

        
    </div>
</section>
    
    
    </div>
  )
}

export default BlogDetailList