import React from 'react'
import { Link } from 'react-router-dom'
import adetail from '../images/taup.jpg'
import customer from '../images/customer-service.png'
import timetable from '../images/timetable.png'
import senddata from '../images/send-data.png'
function Header() {
  return (
    <div>
    <div className="top-bar" style={{backgroundColor:' #F06343'}}>
    <div className="container-fluid">
        <div className="row align-items-center">
            <div className="col-lg-4 col-md-12">
                <div className="logo">
                    <Link to={'/'}>
                        <h1>ጣዝማ </h1>
                         
                    </Link>
                </div>
            </div>
            <div className="col-lg-8 col-md-7 d-none d-lg-block">
                <div className="row">
                    <div className="col-4">
                        <div className="top-bar-item">
                            <div className="top-bar-icon">
                            <img src={timetable} style={{width:'50px',height:'50px'}} alt=''/>
                            </div>
                            <div className="top-bar-text">
                                <h3>Opening Hour</h3>
                                <p>Mon - Sat, 2:00 - 12:00</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="top-bar-item">
                            <div className="top-bar-icon">
                            <img src={customer} style={{width:'50px',height:'50px'}} alt=''/>
                            </div>
                            <div className="top-bar-text">
                                <h3>Call Us</h3>
                                <p>091 123 9615</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="top-bar-item">
                            <div className="top-bar-icon">
                            <img src={senddata} style={{width:'50px',height:'50px'}} alt=''/>
                            </div>
                            <div className="top-bar-text">
                                <h3>Email Us</h3>
                                <p>tazimacons2021@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    


<div className="nav-bar" style={{backgroundColor:' #F06343'}}>
<div className="container-fluid">
    <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
        <Link to={'/'} className="navbar-brand"><img style={{width:'50px'}} className="rounded-circle " src={adetail} alt=''/></Link>
        <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
            <div className="navbar-nav mr-auto">
                <Link to={'/'} className="nav-item nav-link active">Home</Link>
                <Link to={'/about'} className="nav-item nav-link">About</Link>
                
               
               
                <div className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" data-toggle="dropdown">Service</Link>
                    <div className="dropdown-menu">
                        
                        <Link to={'/constraction'} className="dropdown-item" >Tazima Construction </Link>
                        <Link to={'/medical'} className="dropdown-item">Tazima Medicine and medical device import  </Link>
                        <Link to={'/delivery'} className="dropdown-item ">Tazima Delivery  </Link>
                    </div>
                </div>
                <Link to={'/blog'} className="nav-item nav-link">Blog</Link>
                <Link to={'/contact'} className="nav-item nav-link">Contact</Link>
            </div>
            <div className="ml-auto">
            <Link to={'/contact'} className="btn" >Contact us</Link>
            </div>
        </div>
    </nav>
</div>
</div>
    </div>
  )
}

export default Header