import React from 'react'
import Header from './Header'

import Team from './Team'
import adetail from '../images/adetail.jpg'
function About() {
  return (
    <div>
    <Header/>
    <div className="about wow fadeInUp" data-wow-delay="0.1s">
<div className="container">
    <div className="row align-items-center">
        <div className="col-lg-5 col-md-6">
            <div className="about-img">
            <img src={adetail} alt=''/>
            </div>
        </div>
        <div className="col-lg-7 col-md-6">
            <div className="section-header text-left">
                <h1>Welcome to Tazima</h1>
                <hr/>

            </div>
            <div className="about-text">
                <p>
                Tazima is an Ethiopian  company established in 2012 E.C by a 
                young visionary and licensed general contractor 
                called Sertsemichael Guchima (ሠርፀሚካኤል ጉችማ)
                </p>
                <p>
                Sertse established Tazima as a General Contractor with the aim of participating in different architectural and engineering projects to be implemented throughout the country. 
                </p>
                <p>
                Since its establishment, Tazma has undertaken and executed various 
                construction projects of diverse nature ranging from small scale residential buildings to large scale governmental & private complexes. 
            </p>
            <p>
            Tazma has also undergone various changes to grow in capital and experience, and 
            maximize its project execution capacity at the highest level. Beyond accompanying the most experienced and educated men it incorporates 
            young minds and talent to its workforce. It also employed the state of 
            the art machineries. This helps to win and gain its customers confidence and earn a great reputation. Due to this its level has grown up 
            stunningly to GC5.
        </p>
            </div>
        </div>
        <h3>OUR FUTURE</h3>
        <p>
        With our philosophy, values, and mission firmly in place, we are targeting 
        controlled growth and sustained profitability in the future. Our responsibility to our clients, employees, and all 
        stakeholders remains the principal 
        focus of our operations and is defined 
        by our corporate values.
        Tazima Construction PLC, better prepared now than ever before and will 
        take its venture beyond the design 
        stage- creating a physical reality that 
        can be touched and felt. With the 
        rough road ahead, we are ready to 
        take on whatever challenges that 
        come its way without losing sight of 
        its vision; all of us at Tazima Construction PLC, remain committed to 
        excellence and growth.
    </p>



    </div>
</div>
</div>


<Team/>
    </div>
  )
}

export default About