import React from 'react'

import HomBlog from './HomBlog'
import Header from './Header'
import de from '../images/de.jpg'
import homebanner2 from '../images/homebanner2.jpeg'
import ha from '../images/tl.jpg'
import medicene from '../images/medicene.jpg'
import destribution from '../images/destribution.png'
function Health() {
  return (
    <div className="wrapper">
    <Header/>
   
<div id="carousel" className="carousel slide" data-ride="carousel">
<ol className="carousel-indicators">
    <li data-target="#carousel" data-slide-to="0" className="active"></li>
    <li data-target="#carousel" data-slide-to="1"></li>
    <li data-target="#carousel" data-slide-to="2"></li>
</ol>
<div className="carousel-inner">
    <div className="carousel-item active">
    <img src={homebanner2}   alt=''/>
        <div className="carousel-caption">

          
        </div>
    </div>

   

    
</div>


</div>


<div className="about wow fadeInUp" data-wow-delay="0.1s">
<div className="container">
    <div className="row align-items-center">
        <div className="col-lg-5 col-md-6">
            <div className="about-img">
            <img src={ha}   alt=''/>
            </div>
        </div>
        <div className="col-lg-7 col-md-6">
            <div className="section-header text-left">
                <h1>Welcome to Tazima Medicine and Medical Device Import</h1>
                
            </div>
            <div className="about-text">
                <p>
              <strong> Tazima Medicine and Medical Device Import</strong> is one of our organization under Tazima plc which is a prominent Ethiopian organization specializing in the import and distribution of pharmaceuticals and medical devices. With a strong commitment to public health, Tazima Medicine and Medical Device Import plays a vital role in ensuring that essential medicines and cutting-edge medical equipment reach healthcare facilities across the country. Established with a vision to enhance healthcare access, Tazima collaborates closely with international manufacturers, regulatory bodies, and local healthcare providers to deliver high-quality products.
                </p>
            </div>
        </div>
    </div>
</div>
</div>









<div className="container-fluid bg-light py-6  px-0 px-md-5">
        <div className="text-center mx-auto mb-5" style={{maxWidth: '100%'}}>
            <h1 className="display-5 text-uppercase mb-4">Service we provide under Tazima Medicine & medical device Import</h1>
        </div>
        <div className="row w-100 m-0 ">
            <div className="col-md-6 col-lg-4">
                <div className="service-item bg-white d-flex flex-column align-items-center text-center">
                    <img className="img-fluid mb-2" src={medicene} alt=""/>
                    
                    <div className="px-4 pb-4">
                        <h4 className="text-uppercase text-primary  mb-3">Importing Medicine</h4>
                        <p>We specialize in importing pharmaceutical products from various countries. Our services ensure compliance with regulations, quality control, and safety standards. Whether it’s prescription medications or over-the-counter drugs, we handle the entire import process, including documentation, customs clearance, and distribution to pharmacies and healthcare facilities.</p>
                       
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-4">
            <div className="service-item bg-white d-flex flex-column align-items-center text-center">
            <img src={de} className="img-fluid mb-2"  alt=''/>
                
                <div className="px-4 pb-4">
                    <h4 className="text-uppercase text-primary  mb-3">Importing Medical Devices</h4>
                    <p>Our expertise lies in importing medical devices crucial for patient care. From diagnostic equipment to surgical instruments, we navigate regulatory requirements and facilitate smooth imports. Our services encompass product registration, quality assurance, and timely delivery to hospitals, clinics, and research institutions.</p>
                   
                </div>
            </div>
        </div>
            <div className=" col-md-6 col-lg-4">
                <div className="service-item bg-white d-flex flex-column align-items-center text-center">
                    <img className="img-fluid mb-2" src={destribution} alt=""/>
                    
                    <div className="px-4 pb-4">
                        <h4 className="text-uppercase text-primary  mb-3">Distributing  to Health Organizations</h4>
                        <p>We specialize in distributing pharmaceuticals and medical devices to healthcare organizations. Our efficient supply-chain management ensures timely delivery of essential medicines and cutting-edge medical equipment to hospitals, clinics, and research institutions. By partnering with us, health organizations can focus on patient care, knowing that their critical supplies are in capable hands.</p>
                       
                    </div>
                </div>
            </div>
        </div>
        
    </div>


   <HomBlog/>






    </div>
  )
}

export default Health