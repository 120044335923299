import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div>
    
    <div className="footer wow fadeIn" data-wow-delay="0.3s">
    <div className="container">
        <div className="row">
            <div className="col-md-6 col-lg-4">
                <div className="footer-contact">
                    <h2>Office Contact</h2>
                    <p><i className="fa fa-map-marker-alt"></i>Ayat , Addis Ababa, Ethiopia</p>
                    <p><i className="fa fa-phone-alt"></i>+251911239615</p>
                    <p><i className="fa fa-envelope"></i>tazimacons2021@gmail.com</p>
                    <div className="footer-social">
                        <Link className="text-decoration-none "><i className="fab fa-twitter"></i></Link>
                        <Link to={'https://www.facebook.com/tazimacons2013'} className="text-decoration-none "><i className="fab fa-facebook-f"></i></Link>
                        <Link className="text-decoration-none "><i className="fab fa-youtube"></i></Link>
                        <Link className="text-decoration-none "><i className="fab fa-instagram"></i></Link>
                       
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-4">
                <div className="footer-link">
                    <h2>Services Areas</h2>
                    <Link to={'/constraction'} className="text-decoration-none " >Tazima Construction</Link>
                    <Link to={'/medical'} className="text-decoration-none ">Tazima medicine and medical device import</Link>
                    <Link to={'/delivery'} className="text-decoration-none ">Tazima Delivery</Link>

                </div>
            </div>
            <div className="col-md-6 col-lg-4">
                <div className="footer-link">
                    <h2>Useful Pages</h2>
                    <Link to={'/about'} className="text-decoration-none ">About Us</Link>
                    <Link to={'/contact'} className="text-decoration-none ">Contact Us</Link>
                    <Link to={'/blog'} className="text-decoration-none ">Blog</Link>
                    <Link className="text-decoration-none ">Service</Link>
                    
                </div>
            </div>
           
        </div>
    </div>
   
   
</div>
    
    </div>
  )
}

export default Footer