import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import Header from './Header'
import axios from 'axios';

function Blog() {
const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("https://tazima.fortinotech.com.et/blogfetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);

    const truncate = (str, len) => str.slice(0, len);
  return (
    <div>
    <Header/>
<div className="page-nav page-headerr no-margin   ">
<div className="container">
    <div className="row">
        <h2 className="text-white ">ጣዝማ</h2>
        <ul className="d-flex justify-content-center  ">
            <li > <Link to={'/'} className="text-white "><i className="fas fa-home text-white"></i> Home</Link></li>
            <li className="text-white "><i className="fas fa-angle-double-right text-white"></i> Our Blog</li>
        </ul>
    </div>
</div>
</div>
    

<div className="blog">

<div className="container">
    
    <div className="row blog-row  ">
       {data.map((blog,i)=>{
     return   <div className="col-md-6 col-sm-12 gap-1 " key={i}>
        <div className="blog-singe no-margin ">
            <div className="blog-img-tab p-md-2 ">
               { <img className="rounded" src={'https://tazima.fortinotech.com.et/images/' + blog.image} alt=""/>}
            </div>
            <div className=" blog-content-tab">
                <h2>{blog.subject}</h2>
                <p>{blog.date}</p>
                <p className="blog-desic mb-0 ">{truncate(blog.detail, 230)}.... </p>
                <div className='d-flex justify-content-end '>
                <Link className='mt-0 ' to={`/blogdetail/${blog.id}`}>Read More <i className="fas fa-arrow-right"></i></Link>
                </div>
                
            </div>
        </div>
    </div>
       }) }
       
    </div>
</div>
</div>
    

    </div>
  )
}

export default Blog