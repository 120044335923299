import React
, { useEffect,
useState } from 'react'



import AdminHeader from './AdminHeader';
import axios from 'axios';

function Viewmessage() {
const [data, setdata] = useState([]);
const [single, setsingle] = useState(

    {name:'',
    email:'',
    message:'',
    date:'',
    id:'',
    status:'',
    phone:'',
    delwe:null

    }
);
useEffect(() => {
    axios.get("https://tazima.fortinotech.com.et/messages")
    .then(res=>{
if (res.data.status==="success") {
    setdata(res.data.Result.sort((a, b) => b.id - a.id))
    
}
        
    })
    .catch(err=>console.log(err))
   
}, []);
    const selectsingle=(id)=>{
        console.log(id);
axios.get("https://tazima.fortinotech.com.et/read/"+id)
.then(res=>{
   if (res.data.status==="success") {
   
    
        setsingle({
            name:res.data.Result[0].name,
            email:res.data.Result[0].email,
            message:res.data.Result[0].message,
            date:res.data.Result[0].date,
            id:res.data.Result[0].id,
            status:res.data.Result[0].status,
            phone:res.data.Result[0].phone,
            delwe:"ok"
        })
        axios.put("https://tazima.fortinotech.com.et/status/"+id)
        .then(res=>{

        })
        .catch(err=>console.log(err))

    }
}

)
.catch(err=>console.log(err+"naol"))
    }

const handleDelete=(id,name)=>{

    const conf=window.confirm(`Do you Want to Delete ${name}'s message`)
    if(conf){
        axios.delete("https://tazima.fortinotech.com.et/delete/"+id)
        .then(res=>{
          if (res.data.status==="success") {
            alert("you have seccessfully deleted the message!")
            
            
          }
        })
        .catch(err=>console.log(err))
    }

}


  return (
    <div>
    <AdminHeader/>
   
    <div className="container ">

   
    <div className="page-title">
        <div className="row gutters">
           
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5"> </div>
        </div>
    </div>

    <div className="content-wrapper">

    
        <div className="row gutters">

            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  ">

                <div className="card m-0">

                    <div className="row no-gutters ">
                        <div className="col-xl-4 col-lg-4 col-md-4 b col-4">
                            <div className="users-container navbar-nav-scroll   ">
                                <div className="chat-search-box text-light  ">
                                 <h1> User Message List</h1> 
                                </div>
                                
                              {data.map((message,i)=>{
                               
                            return  <ul className="users p-0 mx-1 mx-lg-2  " key={i} >
                              <button type="button" onClick={()=>selectsingle(message.id)} className={`${message.status==="new" && " btn btn-success  person text-white d-flex justify-content-start" } "  person btn btn-secondary text-white d-flex justify-content-start   mb-2   "`} >
                           <li className={"p-0 "} data-chat="person1" >
                            <div className="user " >
                            <span className="name m-1 ">{message.name}</span>
                            </div>
                            
                        </li>
                            </button>
                            </ul>
                              })  }
                           
                             
                                   
                                  
                                
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8  col-8">
                            <div className="selected-user bg-info  ">
                                 <span className="name">{single.email}</span>
                            </div>
                            
                            <div className="chat-container">
                                <ul className="chat-box chatContainerScroll">
                                    
                                  <li className="chat-right">
                                    <div className="chat-hour">{single.date}<span className="fa fa-check-circle"></span></div>
                                        <div className="chat-text">
                                            <br/><p>{single.message}</p> </div>
                                        <div className="chat-avatar">
                                            <img src="https://www.bootdey.com/img/Content/avatar/avatar3.png" alt="Retail Admin"/>
                                            <div className="chat-name">{single.phone}</div>
                                        </div>
                                       
                                        
                                    </li>
                                    <li>
                                   {single.delwe!==null && <div className="del">
                                         <button type="button" onClick={()=>handleDelete(single.id,single.name)} className="btn btn-danger">delete</button>
                                        </div>}
                                    
                                    </li>
                                   
                                </ul>
                               
                            </div>
                        </div>
                    </div>
                   
                </div>

            </div>

        </div>
      

    </div>
   

</div>
    
    </div>
  )
}

export default Viewmessage