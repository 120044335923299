import React from 'react'
import HomBlog from './HomBlog'
import Header from './Header'
import DAbout from '../images/DAbout.png'
import db from '../images/db.jpeg'
import dser from '../images/dser.gif'
import dser2 from '../images/dser2.png'
function Delivery() {
  return (
    <div className="wrapper">
    <Header/>
    <div id="carousel" className="carousel slide" data-ride="carousel">
    <ol className="carousel-indicators">
        <li data-target="#carousel" data-slide-to="0" className="active"></li>
        <li data-target="#carousel" data-slide-to="1"></li>
        <li data-target="#carousel" data-slide-to="2"></li>
    </ol>
    <div className="carousel-inner">
        <div className="carousel-item active">
        <img src={db} alt=''/>
            <div className="carousel-caption">

                <h1 className="animated fadeInLeft">Fast delivery service</h1>
               
            </div>
        </div>

       

        
    </div>

    
</div>
    


<div className="about wow fadeInUp" data-wow-delay="0.1s">
<div className="container">
    <div className="row align-items-center">
        <div className="col-lg-5 col-md-6">
            <div className="about-img">
            <img src={DAbout} alt=''/>
            </div>
        </div>
        <div className="col-lg-7 col-md-6">
        <div className="section-header text-left">
            <h1>Welcome to Tazima Delivery</h1>
            
        </div>
        <div className="about-text">
            <p>
          Delivering Excellence  <strong> Tazima Delivery</strong> , a vital arm of Tazima plc, is committed to seamless logistics and efficient delivery services. With a legacy rooted in 
          precision and innovation, Tazima Delivery ensures that goods reach their destinations promptly and securely. Our fleet of modern vehicles, skilled drivers, and advanced tracking systems guarantee reliable transportation across diverse sectors. Whether it’s last-mile delivery for e-commerce , Tazima Delivery stands as a trusted partner, bridging the gap between suppliers and consumers.
            </p>
        </div>
    </div>
    </div>
</div>
</div>









<div className="container-fluid bg-light py-6 px-0 px-md-5 ">
        <div className="text-center  mb-5" style={{maxWidth: '100%'}}>
            <h1 className="display-5 text-uppercase mb-4  ">Service we provide by Tazima Delivery</h1>
        </div>
        <div className="row w-100 m-0 ">
            <div className="col-md-6">
                <div className="service-item bg-white d-flex flex-column align-items-center text-center">
                    
                    <img className="img-fluid  " src={dser} alt=''/>
                    
                    <div className="p-md-3 p-1 pb-4 ">
                        <h4 className="text-uppercase text-primary  mb-3">የፖስታ (Postal) Delivery</h4>
                        <p>We offer Posta delivery services,ensuring the efficient and timely transportation of mail,packages, and documents from one location to another. Whether it’s letters, parcels, or official correspondence, our secure and reliable delivery ensures smooth communication and document exchange for our clients.</p>
                       
                    </div>
                </div>
            </div>
            <div className=" col-md-6">
                <div className="service-item bg-white d-flex flex-column align-items-center text-center">
                <img className="img-fluid mb-2 " src={dser2} alt=''/>
                    
                <div className="p-md-3 p-1 pb-4 ">
                        <h4 className="text-uppercase text-primary  mb-3">ቀላል ጭነት የማጓጋዝ ስራ</h4>
                        <p>As part of our commitment to efficient delivery.  we provide reliable short-distance material delivery services. Our team transports various materials from suppliers to required sites within a limited radius. By optimizing resource utilization and minimizing downtime, we help you to stay on schedule and build stronger client relationships</p>
                       
                    </div>
                </div>
            </div>
        </div>
        
    </div>


   <HomBlog/>






    </div>
  )
}

export default Delivery